import { observer } from "mobx-react";
import { FC, PropsWithChildren, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { EnterToken, Loading } from "../pages";
import { useAuthStore } from "../app/stores";
import checkToken from "./api/checkToken";
import { message } from "antd";
import styled from "styled-components";
import Sidebar from "./Sidebar";

const Container = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const Content = styled.div`
  @media (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MainLayout: FC<PropsWithChildren<{ title: string }>> = observer(
  ({ children, title }) => {
    const [params] = useSearchParams();
    const { isAuth, user, setIsAuth, setUser } = useAuthStore();

    useEffect(() => {
      if (!isAuth || !user) {
        const token = params.get("token");
        if (token) {
          checkToken(token).then((response) => {
            if (response && typeof response !== "boolean") {
              console.log(typeof response, response);
              setUser(response);
              setIsAuth(true);
            } else {
              message.error("Невалидный токен");
            }
          });
        }
      }
    }, [params, isAuth, user, setUser, setIsAuth]); // Ensure useEffect has correct dependencies

    if (!params.get("token")) {
      return <EnterToken title="Авторизация Avito x amoCRM" />;
    }

    if (!isAuth) {
      return <Loading title="Загрузка..." />;
    }

    return (
      <>
        <title>{title}</title>
        <Container>
          <Sidebar />
          <Content>{children}</Content>
        </Container>
      </>
    );
  }
);

export default MainLayout;
