import { observer } from "mobx-react";
import styled from "styled-components";
import { Button, Flex, Popconfirm, message } from "antd";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { deleteAvitoAccount, refreshAvitoAccount } from "../shared";
import { useAuthStore, useAvitoStore } from "../app/stores";

const AccountCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 1fr 1fr 1fr;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: white;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  a {
    color: #3b5fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    padding-top: 10px;
    padding-bottom: 10px;
    p {
      margin: 0;
      padding: 0;
    }
    p:nth-child(3),
    p:nth-child(4),
    p:nth-child(5),
    p:nth-child(7) {
      display: none;
    }
  }
`;

const Active = styled.div<{ color: "green" | "red" }>`
  width: 12px;
  height: 12px;
  background: ${({ color }) => (color === "green" ? "#34a550" : "#e04646")};
  border-radius: 50px;
`;

const SyncButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvitoAccountCard = observer(
  ({
    id,
    client_number,
    name,
    phone,
    email,
    profile_url,
    created_at,
    active,
  }: any) => {
    const { accounts, setAccounts } = useAvitoStore();
    const { user } = useAuthStore();

    const refreshAccount = async () => {
      const response = await refreshAvitoAccount(id);
      const updatedAccounts = accounts
        .filter((e) => e.id !== id)
        .concat(response?.data);
      setAccounts(updatedAccounts);
    };

    const onConfirm = () => {
      deleteAvitoAccount(id, user.token).then(() => {
        message.success("Аккаунт удален");
        setAccounts(accounts.filter((e) => e.id !== id));
      });
    };

    return (
      <>
        <AccountCard>
          <p>{client_number}</p>
          <p>{name}</p>
          <p>{phone}</p>
          <p>{email}</p>
          <p>
            <Active color={active ? "green" : "red"} />
          </p>
          <p>
            <a href={profile_url} target="_blank" rel="noreferrer">
              {profile_url}
            </a>
          </p>
          <p>{new Date(created_at).toLocaleString()}</p>
          <Flex gap={10} align="center">
            <Popconfirm
              title="Перепривязать аккаунт?"
              description="Если аккаунт неактивен, то перепривяжите аккаунт"
              onConfirm={() => refreshAccount()}
              okText="Перепривязать"
              cancelText="Отмена"
            >
              <SyncButton type="default" style={{ width: 32 }}>
                <SyncOutlined />
              </SyncButton>
            </Popconfirm>
            <Popconfirm
              title="Удалить аккаунт авито?"
              description="При удалении аккаунта вы не сможете поддержать диалог с пользователями, которым отвечали с этого аккаунта"
              onConfirm={onConfirm}
              okText="Удалить"
              cancelText="Отмена"
            >
              <Button danger style={{ width: 110 }}>
                <DeleteOutlined /> Удалить
              </Button>
            </Popconfirm>
          </Flex>
        </AccountCard>
      </>
    );
  }
);

export default AvitoAccountCard;
