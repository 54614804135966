import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";

class AvitoAccountsStore {
  accounts: any[] = [];
  accountsLoaded: boolean = false;
  opennedPopup: boolean = false;

  constructor() {
    makeObservable(this, {
      accounts: observable,
      accountsLoaded: observable,
      opennedPopup: observable,
      setAccounts: action.bound,
      setAccountsLoaded: action.bound,
      setOpennedPopup: action.bound,
    });
  }

  setAccounts(accounts: any[]) {
    this.accounts = accounts;
  }

  setAccountsLoaded(b: boolean) {
    this.accountsLoaded = b;
  }

  setOpennedPopup(b: boolean) {
    this.opennedPopup = b;
  }
}

export const avitoStore = new AvitoAccountsStore();
export const AvitoStoreContext = createContext(avitoStore);
export const useAvitoStore = () => useContext(AvitoStoreContext);
