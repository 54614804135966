import { observer } from "mobx-react";
import { FC } from "react";
import styled from "styled-components";

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  div {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h1 {
    font-size: 1.6em;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 500;
  }

  p {
    font-size: 0.9em;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1.48;
  }
`;

const Loading: FC<{ title: string }> = observer(({ title }) => {
  return (
    <>
      <title>{title}</title>
      <Layout>
        <div>
          <h1>Загрузка</h1>
          <p>Мы проверяем данные, пожалуйста, подождите...</p>
        </div>
      </Layout>
    </>
  );
});

export default Loading;
