import { observer } from "mobx-react";
import { Logs, LogsResponse } from "../types";
import { useEffect, useState } from "react";
import { Modal, Pagination, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useAuthStore } from "../app/stores";
import { getLogs } from "../shared";

const LogList = observer(() => {
  const [logs, setLogs] = useState<Logs[]>([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedLog, setSelectedLog] = useState<Logs | null>(null);
  const { user } = useAuthStore();

  useEffect(() => {
    getLogs({ page: currentPage, pageSize, token: user.token }).then(
      (e: LogsResponse) => {
        setLogs(e.logs);
        setTotalLogs(e.totalLogs);
      }
    );
  }, [currentPage, pageSize, user.token]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      width: "5%",
    },
    {
      title: "URI",
      dataIndex: "url",
      key: "uri",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Тело",
      dataIndex: "body",
      key: "body",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Запрос",
      dataIndex: "request",
      key: "request",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Ответ",
      dataIndex: "response",
      key: "response",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Статус код",
      dataIndex: "status_code",
      key: "status_code",
      width: "5%",
    },
    {
      title: "Время выполнения",
      dataIndex: "timestamp",
      key: "time",
      width: "5%",
    },
    {
      title: "Создано",
      dataIndex: "created_at",
      key: "created",
      width: "10%",
      render: (text: Date) => (
        <span>{new Date(text).toLocaleString("ru")}</span>
      ),
    },
    {
      title: "Обновлено",
      dataIndex: "updated_at",
      key: "updated",
      width: "10%",
      render: (text: Date) => (
        <span>{new Date(text).toLocaleString("ru")}</span>
      ),
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <Table
        dataSource={logs}
        columns={columns}
        rowKey="id"
        pagination={false}
        onRow={(record: Logs) => ({ onClick: () => setSelectedLog(record) })}
        scroll={{ x: "100%" }}
      />

      <Pagination
        style={{ marginTop: "20px" }}
        current={currentPage}
        pageSize={pageSize}
        total={totalLogs}
        onChange={handlePageChange}
        defaultPageSize={5}
      />

      <Modal
        title="Просмотр запроса"
        open={selectedLog !== null}
        onOk={() => setSelectedLog(null)}
        onCancel={() => setSelectedLog(null)}
      >
        {selectedLog ? (
          <>
            <p>ID: {selectedLog.id}</p>
            <p>Тип: {selectedLog.type}</p>
            <p>URL: {selectedLog.url}</p>
            <p>Статус код: {selectedLog.status_code}</p>
            <p>Время выполнения: {selectedLog.timestamp}</p>
            <p>
              Дата создания: {new Date(selectedLog.created_at).toLocaleString()}
            </p>
            <p>
              Дата изменения:{" "}
              {new Date(selectedLog.updated_at).toLocaleString()}
            </p>
            <p>Запрос:</p>
            <TextArea contentEditable={false} value={selectedLog.request} />
            <p>Тело запроса:</p>
            <TextArea contentEditable={false} value={selectedLog.body} />
            <p>Ответ:</p>
            <TextArea contentEditable={false} value={selectedLog.response} />
          </>
        ) : (
          <>Ошибка: запись не выбрана</>
        )}
      </Modal>
    </>
  );
});

export default LogList;
