import axios from "axios";

const api = axios.create({
  baseURL: "https://avitoamo.bpm-run.ru/api/v1",
  headers: {
    Authorization: `Bearer ${localStorage.token}`
  }
});

export default api;
