import { observer } from "mobx-react";
import { AddAvitoAccount, MainLayout, PageTitle } from "../shared";
import { FC } from "react";
import { AvitoAccountList } from "../widgets";
import { Button } from "antd";
import { useAvitoStore } from "../app/stores";

const AvitoAccounts: FC<{ title: string }> = observer(({ title }) => {
  const { setOpennedPopup } = useAvitoStore();

  return (
    <MainLayout title={title}>
      <PageTitle>Аккаунты авито</PageTitle>
      <AddAvitoAccount />
      <Button onClick={() => setOpennedPopup(true)}>Добавить аккаунт</Button>
      <AvitoAccountList />
    </MainLayout>
  );
});

export default AvitoAccounts;
