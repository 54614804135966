import { Input, Modal, message } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
import { addAvitoAccount } from "../shared";
import { useAuthStore, useAvitoStore } from "../app/stores";

const addAccountModal = observer(() => {
  const [client_id, setClientId] = useState<string>("");
  const [client_secret, setClientSecret] = useState<string>("");

  const { setAccounts, accounts, opennedPopup, setOpennedPopup } =
    useAvitoStore();

  const { user } = useAuthStore();

  const sendRequest = async () => {
    const req = await addAvitoAccount(client_secret, client_id, user.token);
    if (req!.status > 300) return;
    setAccounts(accounts.concat(req?.data));
    message.success("Аккаунт добавлен");
    setOpennedPopup(false);
  };

  return (
    <Modal
      title="Добавление аккаунта"
      open={opennedPopup}
      onCancel={() => setOpennedPopup(false)}
      onOk={sendRequest}
    >
      <p>client_id</p>
      <Input
        placeholder="client_id"
        onChange={({ target }) => setClientId(target.value)}
        aria-description="client_id"
      />
      <p>client_secret</p>
      <Input
        placeholder="client_secret"
        onChange={({ target }) => setClientSecret(target.value)}
      />
    </Modal>
  );
});

export default addAccountModal;
