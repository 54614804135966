import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { mainRouter } from "./app/routers";
import "./app/style.css";
import { Provider } from "mobx-react";
import { authStore, avitoStore, logsStore } from "./app/stores";

const router = createBrowserRouter([...mainRouter]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider authStore={authStore} avitoStore={avitoStore} logsStore={logsStore}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
