import api from "../../app/api";

const deleteAvitoAccount = async (id: number, token: string) => {
  const body = {
    id,
  };
  return await api.post(`avito/delete-account`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default deleteAvitoAccount;
