import { observer } from "mobx-react";
import { MainLayout, PageTitle } from "../shared";
import { FC } from "react";
import { LogList } from "../widgets";

const Logs: FC<{ title: string }> = observer(({ title }) => {
  return <MainLayout title={title}>
    <PageTitle>Логи</PageTitle>
    <LogList />
  </MainLayout>;
});

export default Logs;
